<template>
    <div id="room-view">
        <Toast />
        <div class="relative mx-auto bg-blue-50">
            <RoomEditorNavbar @save="onSubmit"/>

            <div class="flex flex-col lg:flex-row w-full justify-center border-t border-gray-200 bg-white" style="border-radius: 32px;">
                <!-- Page Navigation (Sticky) -->
                <div class="page-nav sticky top-0 lg:h-screen lg:mr-16">
                    <div class="flex flex-row-reverse">
                        <RoomEditorPages/>
                    </div>
                </div>

                <!-- Main Content (Block Editor) -->
                <div class="flex justify-center">
                    <div class="w-full max-w-[calc(100%-200px)] lg:max-w-none">
                        <BlockEditor
                            :content="currentPage.content"
                            :on-update-room="onSubmit"
                            :read-only="!editable"
                            :should-scroll="true"
                        />
                    </div>
                </div>
            </div>

        </div>
        <div class="fixed z-[100] right-6 bottom-4" v-if="canChat() && !isEditor">
            <RoomComments :room="page.props.room.name"/>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { usePage, router } from '@inertiajs/vue3';
import axios from "axios";
import { useRoomPage } from '../hooks/useRoomPage.js';
import { useAuth } from '../../../hooks/useAuth.js';
import RoomComments from '../../../Components/comments/RoomComments.vue';
import BlockEditor from '@/tiptap/components/BlockEditor/BlockEditor.vue';
import { useTipTapStore } from '../../../tiptap/stores/useTipTapStore.js';
import RoomEditorNavbar from "./RoomEditorNavbar.vue";
import {usePusher} from "../../../hooks/usePusher.js";
import {displayErrorMessagesAsToast} from "../../../utils/errorUtils.js";
import RoomEditorPages from "@/pages/RoomDetail/components/RoomEditorPages.vue";
import { useToast } from 'primevue/usetoast';
const toast = useToast();
const props = defineProps({
    room: {
        type: Object,
        default: null
    }
})

const page = usePage();
usePusher();
const room = ref(props.room || page.props.room);
const {canModifyRoom, canChat} = useAuth();
const isEditor = ref(page.url.includes('room-detail'));
const {
    editable
} = useRoomPage(props.room);

const currentPage = computed(() => {
    return page.props.currentPage;
})

const tiptapStore = useTipTapStore();

onMounted(() => {
    tiptapStore.setCurrentRoom(room.value.ulid);
})

async function onSubmit(value) {
    console.log('SUBMIT');
    await axios.put(`/page/${currentPage.value.ulid}`, {
        content: value,
        contentStringify: JSON.stringify(value),
        name: currentPage.value.name,
    })
        .then(() => {
            tiptapStore.setSaving(false);
            tiptapStore.setDirty(false);
        })
        .catch((err) => {
            displayErrorMessagesAsToast(err, toast)
        });
}

</script>
<style scoped>

.page-nav {
    z-index: 100;
    @apply sticky top-12 right-24;
}
</style>

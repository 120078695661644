<template>
  <div>
      <div class="flex h-full lg:min-w-[1088px]" ref="menuContainerRef" :class="props.containerClass">
          <div
              class="relative flex flex-col flex-1 h-full overflow-visible "
              :class="{
                    'readonly-editor': readOnly,
                }"
              style="overflow-y: visible !important;"
          >
              <EditorContent :editor="editor" :ref="editorRef" class="flex-1 overflow-y-auto"/>
              <template v-if="editor && !readOnly">
                  <TextMenu :editor="editor"/>
                  <ColumnsMenu :editor="editor" :appendTo="menuContainerRef"/>
                  <LinkMenu :editor="editor" :appendTo="menuContainerRef"/>
                  <ContentItemMenu
                      v-if="!readOnly"
                      :editor="editor"
                  />
                  <TableRowMenu :editor="editor" :appendTo="menuContainerRef"/>
                  <TableColumnMenu :editor="editor" :appendTo="menuContainerRef"/>
                  <ImageBlockMenu :editor="editor" :appendTo="menuContainerRef"/>
                  <PlaceholderMenu :editor="editor" :appendTo="menuContainerRef" v-if="!hidePlaceholderMenu"/>
                  <AddEmbedDialog
                      v-if="store.showEmbedDialog"
                      v-model:visible="store.showEmbedDialog"
                      @submit="onEmbedSubmit"
                      @close="store.toggleShowEmbedDialog(false)"
                  />
                  <BaseAvatarPicker
                      v-model:visible="store.showUploadDialog"
                      ref="avatarPicker"
                      :active-tab="store.uploadDialogTab"
                      :upload-function="createImage"
                      :on-select-library="onSelectLibrary"
                      :append-to-body="false"
                  />
              </template>
          </div>
      </div>
  </div>
</template>
<script setup>
import '@/tiptap/styles/index.css'
import { EditorContent } from '@tiptap/vue-3'
import { useBlockEditor } from '@/tiptap/composables/useBlockEditor.ts'
import ContentItemMenu from "@/tiptap/components/menus/ContentItemMenu/ContentItemMenu.vue";
import TableColumnMenu from "@/tiptap/extensions/Table/menus/TableColumn/TableColumnMenu.vue";
import TableRowMenu from "@/tiptap/extensions/Table/menus/TableRow/TableRowMenu.vue";
import TextMenu from "@/tiptap/components/menus/TextMenu/TextMenu.vue";
import LinkMenu from "@/tiptap/components/menus/LinkMenu/LinkMenu.vue";
import ColumnsMenu from "@/tiptap/extensions/MultiColumn/menus/ColumnsMenu.vue";
import ImageBlockMenu from "@/tiptap/extensions/ImageBlock/components/ImageBlockMenu.vue";
import PlaceholderMenu from "@/tiptap/components/menus/PlaceholderMenu/PlaceholderMenu.vue";
import RoomEditorPages from "@/pages/RoomDetail/components/RoomEditorPages.vue";
import { useTipTapStore } from '../../stores/useTipTapStore.js';
import AddEmbedDialog from '../../extensions/Embed/components/AddEmbedDialog.vue';
import { convertUrlToEmbed } from '@/tiptap/lib/utils/convertUrlToEmbed';
import BaseAvatarPicker from '../../../Components/forms/BaseAvatarPicker.vue';
import { useBlockImage } from '../../../hooks/useBlockImage.js';
import { usePage } from '@inertiajs/vue3';
import {ref, watch} from 'vue';
import { getImgSize } from '../../../utils/imageUtils.js';
import {isImage} from "../../../utils/documentUtils.js";
import {prepareEditor} from "../../extensions/SlashCommand/commands";
const props = defineProps({
    content: {
        type: String,
        default: null
    },
    onUpdate: {
        type: Function,
        default: () => {}
    },
    onUpdateRoom: {
        type: Function,
        default: () => {}
    },
    containerClass: {
        type: String,
        default: ""
    },
    hidePlaceholderMenu: {
        type: Boolean,
        default: false
    },
    withNav: {
        type: Boolean,
        default: true
    },
    readOnly: {
        type: Boolean,
    },
    shouldScroll: {
        type: Boolean,
        default: false
    }
})

const store = useTipTapStore();

const {uploadImage} = useBlockImage();
const { editor, setContent } = useBlockEditor(props.content, props.onUpdate, props.onUpdateRoom, props.shouldScroll)

watch(() => props.content, (content) => {
    setContent(content)
}, { deep: true, immediate: true })

const menuContainerRef = ref(null)
const editorRef = ref(null)
const onEmbedSubmit = async (data) => {
    store.toggleShowEmbedDialog(false);

    const result = await convertUrlToEmbed(data)

    editor.value.chain().focus()
        .setIframe({
            html: result.html ? result.html?.replace('data-iframely-url', 'src') : '<iframe src="'+data+'" frameborder="0" allowfullscreen style="width: 100%;"></iframe>',
            small: result?.options?.card !== undefined,
        })
        .run()
}

async function createImage(file) {
    uploadImage(file, usePage().props.room, async (url, response) => {
        let width = 0;
        let height = 0;
        try {
            const imgSize = await getImgSize({ src: url })
            width = imgSize.width
            height = imgSize.height
        } catch (e) {

        }

        const media = response.data.media;

        prepareEditor(editor.value)
            .setImage({
                src: url,
                uuid: media.uuid,
                file_name: media.file_name,
                width,
                height,
            }).run();

        store.toggleShowUploadDialog(false);
    })
}

function onSelectLibrary(media) {
    const image = isImage(media)
    if (image) {
        prepareEditor(editor.value)
            .setImage({
                src: media.preview_url,
                file_name: media.file_name,
                width: media.width,
                height: media.height,
                uuid: media.uuid,
            }).run();
    } else {
        prepareEditor(editor.value)
            .setFilePreview({
                id: media.uuid,
                file_name: media.file_name,
                name: media.name,
                src: media.preview_url,
                size: media.size,
            })
            .run()
    }

    store.toggleShowUploadDialog(false);
}

function setEditable(value) {
    if (!editor.value) {
        return
    }
    editor.value?.setEditable(value)
}

watch(() => props.readOnly, (readonly) => {
    setEditable(!readonly)
}, { immediate: true})

// watch(() => editor.value, () => setEditable(!props.readOnly))

</script>
<style lang="postcss">
.readonly-editor .card-buttons{
    @apply hidden;
}
</style>
